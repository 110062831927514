import {
    b,
    code,
    codeBlock,
    doc,
    heading,
    link,
    listItem,
    p,
    text,
    ul,
    inlineCard,
    strong
} from "@atlaskit/adf-utils/builders";
import { Octokit } from "@octokit/core";

const PR_COMMIT_REGEX = /^(?<message>.+?)(?:\(#(?<pr_number>\d+)?\))\n?/;
const PR_MESSAGE_JIRA_REGEX = /^(SP|RA|REC|PT|IN)(-[0-9]+)(.+)/;
const li = (...content) => listItem(content);
const a = (href, content) => link({ href })(text(content));

export default class IssueDescription {
    constructor(githubToken, pullRequests, jiraToken) {
        this.pullRequests = pullRequests;
        this.commitsByPR = {};
        this.jiraStatusByKey = {};
        this.octokit = new Octokit({ auth: githubToken.accessToken });
        this.jiraToken = jiraToken;
    }

    async generate(isForPreview) {
        return doc(
            heading({ level: 3 })(text("PRs")),
            await this._pullRequestsOverview(isForPreview),
            heading({ level: 3 })(text("Release")),
            await this._releaseSteps(),
            heading({ level: 3 })(text("Post-Release"))
        );
    }

    async _getCommits(pullRequest) {
        if (!this.commitsByPR[pullRequest.id]) {
            const commits = await this.octokit.request(
                `GET ${pullRequest.commits_url}`,
                { per_page: 250 }
            );
            this.commitsByPR[pullRequest.id] = commits.data.filter(({ commit: { message } }) =>
                !message.startsWith("Merge branch") && !message.startsWith("Merge pull request")
            );
        }
        return this.commitsByPR[pullRequest.id];
    }

    async _fetchStatus(key) {
        const { accessToken, cloudId } = this.jiraToken;
        if (!this.jiraStatusByKey[key]) {
            const status = await fetch(
                `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/3/issue/${key}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return { fields: { status: " " } };
                    }
                })
                .then(
                    ({
                        fields: {
                            status: { name },
                        },
                    }) => name
                )
                .catch((err) => err);
            if (typeof status !== "undefined") {
                this.jiraStatusByKey[key] = status;
            } else {
                this.jiraStatusByKey[key] = " ";
            }
        }
        return this.jiraStatusByKey[key];
    }
    _getColorByStatus(status) {
        switch (status) {
        case "In Progress":
            return "#e4b13d";
        case "Dev Complete":
            return "#f5838f";
        case "QA / User Acceptance":
            return "#cb8e11";
        case "Done":
            return "#39b37b";
        case "Blocked":
            return "#ff0000";
        default:
            return "#000000";
        }
    }
    // TODO create draft release (https://github.com/kyle-st/aws-lambda-functions/tree/jiraDeployTicket_draft_release)
    async _pullRequestsOverview(isForPreview) {
        const prDetails = this.pullRequests.map(async (pullRequest) => {
            const {
                base: {
                    repo: { name: repoName, html_url: repoUrl },
                },
                title,
                html_url: prUrl,
            } = pullRequest;
            const commits = await this._getCommits(pullRequest);
            let statusByKey = {};
            await Promise.all(
                commits.map(({ commit: { message } }) => {
                    const match = message.match(PR_MESSAGE_JIRA_REGEX);
                    if (match) {
                        const [, projectAbbr, jiraNumber] = match;
                        return this._fetchStatus(projectAbbr + jiraNumber).then(
                            (status) => {
                                statusByKey[projectAbbr + jiraNumber] = status;
                            }
                        );
                    } else {
                        return Promise.resolve();
                    }
                })
            );
            return li(
                p(a(prUrl, `${repoName} - ${title}`)),
                ul(
                    ...commits.map(({ sha, commit: { message }, html_url }) => {
                        const match = message.match(PR_COMMIT_REGEX);
                        const matchJira = message.match(PR_MESSAGE_JIRA_REGEX);
                        if (match && match[2]) {
                            const [, commitMessageText, prNumber] = match;
                            if (matchJira) {
                                const [, projectAbbr, jiraNumber, comment] = matchJira,
                                    prNumberDisplay = `(#${prNumber})`,
                                    commentWithoutPrNumber = comment.replace(` ${prNumberDisplay}`, ''),
                                    jiraName = projectAbbr + jiraNumber;
                                if (isForPreview) {
                                    return li(
                                        p(
                                            a(
                                                `https://sharetown.atlassian.net/browse/${jiraName}`,
                                                jiraName
                                            ),
                                            {
                                                type: "text",
                                                text: ` ${statusByKey[jiraName]}`,
                                                marks: [
                                                    {
                                                        type: "textColor",
                                                        attrs: {
                                                            color: `${this._getColorByStatus(
                                                                statusByKey[jiraName]
                                                            )}`,
                                                        },
                                                    },
                                                ],
                                            },
                                            text(commentWithoutPrNumber),
                                            text(" "),
                                            a(`${repoUrl}/pull/${prNumber}`, prNumberDisplay)
                                        )
                                    );
                                } else {
                                    return li(
                                        p(
                                            inlineCard({
                                                url: `https://sharetown.atlassian.net/browse/${jiraName}`,
                                            }),
                                            text(commentWithoutPrNumber),
                                            text(" "),
                                            a(`${repoUrl}/pull/${prNumber}`, prNumberDisplay)
                                        )
                                    );
                                }
                            } else {
                                return li(
                                    p(
                                        text(commitMessageText),
                                        a(`${repoUrl}/pull/${prNumber}`, `(#${prNumber})`)
                                    )
                                );
                            }
                        } else {
                            if (matchJira) {
                                const [, projectAbbr, jiraNumber, comment] = matchJira;
                                const jiraName = projectAbbr + jiraNumber;
                                return li(
                                    p(
                                        a(
                                            `https://sharetown.atlassian.net/browse/${jiraName}`,
                                            jiraName
                                        ),
                                        text(comment),
                                        text(" "),
                                        a(html_url, `(${sha.slice(0, 7)})`)
                                    )
                                );
                            } else {
                                return li(
                                    p(
                                        text(message),
                                        text(" "),
                                        a(html_url, `(${sha.slice(0, 7)})`)
                                    )
                                );
                            }
                        }
                    })
                )
            );
        });
        return ul(...(await Promise.all(prDetails)));
    }

    // TODO infer previous release for UI deploys, if possible
    async _releaseSteps() {
        const releaseSteps = this.pullRequests.map(async (pullRequest) => {
            const {
                base: {
                    repo: { name: repoName },
                },
                html_url,
            } = pullRequest;
            const defaultPPV = ul(
                ...(await this._getCommits(pullRequest)).map(
                    ({ commit: { message } }) =>
                        li(p(text(`Verify ${message.split("\n")[0]}`)))
                )
            );
            const projectSpecificReleaseSteps = (() => {
                switch (repoName) {
                case "sharetown-db":
                    // TODO infer rollback tag from commits
                    return [
                        p(text("sharetown-db")),
                        ul(
                            li(
                                p(
                                    text("Merge "),
                                    a(html_url, "pull request"),
                                    text(" (create merge commit)")
                                )
                            ),
                            li(
                                p(
                                    a(
                                        "https://us-west-2.console.aws.amazon.com/codesuite/codepipeline/pipelines/prod-sharetown-db/view?region=us-west-2",
                                        "Release"
                                    )
                                )
                            ),
                            li(p(text("PPV")), defaultPPV),
                            li(
                                p(text("Rollback")),
                                ul(
                                    li(p(text("Checkout release branch"))),
                                    li(
                                        p(text("Execute rollback procedure")),
                                        ul(
                                            li(
                                                p(text("Rollback tag:")),
                                                ul(li(p(b(text("<TAG>")))))
                                            ),
                                            li(
                                                codeBlock({ language: "shell" })(
                                                    text(
                                                        [
                                                            'export LIQUIBASE_COMMAND="rollback <TAG>"',
                                                            'export RDSHOST="sharetown-cluster-prod.cluster-cqlzrcxxvylf.us-west-2.rds.amazonaws.com"',
                                                            'export LIQUIBASE_PASSWORD="$(aws rds generate-db-auth-token --hostname $RDSHOST --port 5432 --region us-west-2 --username sharetown)"',
                                                            'export LIQUIBASE_URL="jdbc:postgresql://$RDSHOST/sharetown?sslmode=require"',
                                                            'docker-compose run --rm -e LIQUIBASE_URL=$LIQUIBASE_URL -e LIQUIBASE_USERNAME=sharetown -e LIQUIBASE_PASSWORD="$LIQUIBASE_PASSWORD" liquibase $LIQUIBASE_COMMAND',
                                                        ].join("\n")
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                    ];
                case "sharetown-api":
                    return [
                        p(text("sharetown-api")),
                        ul(
                            li(
                                p(
                                    text("Merge "),
                                    a(html_url, "pull request"),
                                    text(" (create merge commit)")
                                )
                            ),
                            li(
                                p(
                                    a(
                                        "https://us-west-2.console.aws.amazon.com/codesuite/codepipeline/pipelines/prod-sharetown-api/view?region=us-west-2",
                                        "Release"
                                    )
                                )
                            ),
                            li(p(text("PPV")), defaultPPV),
                            li(
                                p(text("Rollback")),
                                ul(
                                    li(
                                        p(
                                            text("Update "),
                                            a(
                                                "https://us-west-2.console.aws.amazon.com/ecs/home?region=us-west-2#/clusters/sharetown-prod/services/sharetown-api/details",
                                                "sharetown-api service"
                                            ),
                                            text(" to task definition "),
                                            // TODO infer task definition through AWS API, and link to it
                                            a("#", "sharetown-api-prod:00")
                                        )
                                    )
                                )
                            )
                        ),
                    ];
                case "sharetown-admin-ui":
                    return [
                        p(text("sharetown-admin-ui")),
                        ul(
                            li(
                                p(
                                    text("Merge "),
                                    a(html_url, "pull request"),
                                    text(" (create merge commit)")
                                )
                            ),
                            li(
                                p(
                                    a(
                                        "https://us-west-2.console.aws.amazon.com/codesuite/codepipeline/pipelines/prod-admin.sharetown.io/view?region=us-west-2",
                                        "Release"
                                    )
                                )
                            ),
                            li(p(text("PPV")), defaultPPV),
                            li(
                                p(text("Rollback")),
                                ul(
                                    li(
                                        p(
                                            text(
                                                "revert master branch to <previous release branch> and re-release"
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                    ];
                case "sharetown-partner-ui":
                    return [
                        p(text("sharetown-partner-ui")),
                        ul(
                            li(
                                p(
                                    text("Merge "),
                                    a(html_url, "pull request"),
                                    text(" (create merge commit)")
                                )
                            ),
                            li(
                                p(
                                    a(
                                        "https://us-west-2.console.aws.amazon.com/codesuite/codepipeline/pipelines/prod-partner.sharetown.io/view?region=us-west-2",
                                        "Release"
                                    )
                                )
                            ),
                            li(p(text("PPV")), defaultPPV),
                            li(
                                p(text("Rollback")),
                                ul(
                                    li(
                                        p(
                                            text(
                                                "revert master branch to <previous release branch> and re-release"
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                    ];
                case "sharetown-rep-ui":
                    return [
                        p(text("sharetown-rep-ui")),
                        ul(
                            li(
                                p(
                                    text("Merge "),
                                    a(html_url, "pull request"),
                                    text(" (create merge commit)")
                                )
                            ),
                            ...["Android", "iOS", "Web"].map(app => li(
                                p(
                                    a(
                                        `https://us-west-2.console.aws.amazon.com/codesuite/codepipeline/pipelines/prod-sharetown-rep-ui-${app.toLowerCase()}/view?region=us-west-2`,
                                        `Release ${app}`
                                    )
                                ),
                                ...[ul(
                                    li(
                                        p(
                                            strong("Note: "),
                                            "Web application has a manual approval step. This is added to accommodate the different schedule of Rep App release. A UI developer will approve that step at a later time."
                                        )
                                    )
                                )].filter(() => app === "Web"),
                                ...[ul(
                                    li(
                                        p(
                                            strong("Note: "),
                                            `${app} application has a manual approval step. This is used to control when the new app version is announced to users. A UI developer will approve that step at a later time.`
                                        )
                                    )
                                )].filter(() => app !== "Web")
                            )),
                            li(p(text("PPV")), defaultPPV),
                            li(
                                p(text("Rollback")),
                                ul(
                                    li(
                                        p(
                                            text(
                                                "revert master branch to <previous release branch> and re-release"
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                    ];
                case "sharetown-marketplace":
                    return [
                        p(text("sharetown-marketplace")),
                        ul(
                            li(
                                p(
                                    text("Merge "),
                                    a(html_url, "pull request"),
                                    text(" (create merge commit)")
                                )
                            ),
                            li(
                                p(
                                    text("Access the server via "),
                                    code("ssh"),
                                    text(" and navigate to "),
                                    code("recoopit.com"),
                                    text(", then run "),
                                    code("git pull"),
                                    text(" to pull all the changes.")
                                )
                            ),
                            li(
                                p(
                                    text("Verify if the production is in "),
                                    code("production mode"),
                                    text(" with "),
                                    code("bin/magento deploy:mode:show"),
                                    text(", if it is run the commands in order:")
                                )
                            ),
                            li(
                                codeBlock({ language: "shell" })(
                                    text(
                                        [
                                            "bin/magento maintenance:enable",
                                            "rm -r generated/*",
                                            "bin/magento setup:upgrade",
                                            "bin/magento setup:di:compile",
                                            "bin/magento setup:static-content:deploy",
                                            "bin/magento maintenance:disable",
                                            "",
                                            "bin/magento weltpixel:cleanup",
                                            "bin/magento weltpixel:less:generate",
                                            "bin/magento ca:cl",
                                        ].join("\n")
                                    )
                                )
                            ),
                            li(
                                p(
                                    text("PPV at "),
                                    code("recoopit.com"),
                                    text(
                                        ". Please notice that in the first visit after a deploy, the navigation will be slow since Magento still generate some content."
                                    )
                                ),
                                defaultPPV
                            ),
                            li(p(text("Rollback")), ul(li(p(text("TBD")))))
                        ),
                    ];
                default:
                    return [p(b(text(`RELEASE STEPS GO HERE for ${repoName}`)))];
                }
            })();
            return li(...projectSpecificReleaseSteps);
        });
        return ul(...(await Promise.all(releaseSteps)));
    }
}
