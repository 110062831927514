import {useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AppDownload {
    static EXPECTED_HOST = "codepipeline-us-west-2-370770191675.s3.us-west-2.amazonaws.com";

    static render() {
        const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const filename = searchParams.get("filename") || "Download";
        const description = searchParams.get("description");
        const icon = searchParams.get("icon");
        try {
            const url = new URL(searchParams.get("url"));
            const embeddedUrlHasExpectedHost = () => {
                const embeddedUrlParam = url.searchParams.get("url");
                const embeddedUrl = new URL(embeddedUrlParam);
                return embeddedUrl.host === AppDownload.EXPECTED_HOST;
            };
            if (url.host === AppDownload.EXPECTED_HOST || embeddedUrlHasExpectedHost()) {
                return <div>
                    {AppDownload.HEADER}
                    {description && <p>{description}</p>}
                    <Button href={url.href} size="lg" style={{fontSize: "1.6em"}}>
                        {icon && <span>
                            <FontAwesomeIcon size="lg" icon={["fab", icon]}/>
                            &nbsp; &nbsp;
                        </span>}
                        {filename}
                    </Button>
                </div>;
            } else {
                console.warn("invalid url: neither url nor embedded url had expected host", url);
                return AppDownload.INVALID_URL;
            }
        } catch (e) {
            console.warn("invalid url", e);
            return AppDownload.INVALID_URL;
        }
    }

    static HEADER = <h1 className="h3 mb-3">App Download</h1>;

    static INVALID_URL = <div>
        {AppDownload.HEADER}
        <div>Invalid URL</div>
    </div>;
}

export default AppDownload.render;
